<template>
  <div style="background-color: #f5faff">
    <NavBar style="max-height: 50px;" :pageName="pageName" class="docViewAppointmentNavBar"></NavBar>

    <v-app>
      <!-- start Confirmation for submission prescription -->
      <div>
        <v-row justify="center">
          <v-dialog
            v-model="presc_dialog"
            persistent
            max-width="500"
          >
            <v-card>
              <v-card-title class="text-h5" style="word-break: break-word !important">
                CONFIRMATION FOR SUBMISSION
              </v-card-title>
              <v-card-text>Once a prescription has been submitted, it cannot be changed.</v-card-text>
              <v-card-actions>
                <v-btn
                  color="warning"
                  @click="presc_dialog = false"
                  style="background-color: #EB5757;"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="primary"
                  @click="save_prescription()"
                  style="background-color: #1467BF;"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
       <!-- end Confirmation for submission prescription -->

      <!-- start submission part for prescription -->
      <div>
        <v-row justify="center">
          <v-dialog
            v-model="save_dialog"
            persistent
            max-width="500"
          >
            <v-card>
              <v-card-title style="word-break: break-word !important" class="text-h5">
                Prescription Saved Successfully
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="saved_prescription()"
                  style="background-color: #1467BF;"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <!-- end submission part for prescription -->

      <div style="background-color: #f5faff; height: 94.5vh !important;">
         <!-- <v-row cols="12"> -->
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName" class="docViewAppointmentNavBar"></SideBar>
        </v-col>
        <div v-if="!isLoading" style="position: fixed; left: 300px;top: 80px; width: 70vw;overflow: scroll; height: 80vh;" class="docViewAppointmentMainContainer">
            <h3
              style="
                text-align: left;
                margin-top: 30px;
                font-weight: 800;
                margin-bottom: 10px;
                margin-left: 10px;"
              class="titleClass"
            >
              Appointment Details
            </h3>
            <v-card flat style="margin-bottom: 8px">
            <div
              class="header"
              style="
                display: flex;
                flex-direction: row;
                padding-top: 10px;
                padding-left: 5px;
              ">
              <div class="bookAppointmentMainDiv" style="display: flex; flex-direction: row; width: 100%">
                <div
                    style="width: 10%; padding-top: 6px"
                    align="left"
                    v-on:click="redirectToHomePage()"
                  >
                  <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                </div>
                <div style="width: 90%" align="left">
                  <span style="font-weight: 600; font-size: 15pt">Appointment Details</span>
                </div>
              </div>
            </div>
          </v-card>
            <v-card style="width: 70vw;" class="docViewAppointmentPatientInfo">
              <div style="display: flex; flex-direction: column">
                <div align="left" style="padding: 24px 32px 0 32px">
                  <p style="font-weight: bold; margin-bottom: 3px">
                    BOOKING ID: {{ appointment_reference_number }}
                  </p>
                </div>
                <v-divider />
                <div style="padding: 24px 32px 0 32px;">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;">
                    <div
                      style="
                        display: flex;
                        flex-direction: row;">
                      <div>
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </div>
                      <div style="display: flex; flex-direction: column; margin-left: 10px;" align="left">
                        <div style="color: #828282">Patient Name</div>
                        <div style="font-weight: bold" align="left">
                          {{ nameOfPatient }}
                        </div>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-right: 50px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div align="center">
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">Date</div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                              <DateRendered :dateVal="dateOfAppointment"> </DateRendered>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-left: 20px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/clock.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">
                            Time Slot
                          </div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                            <TimeRender :timeSlot="timeSlot"> </TimeRender>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-left: 32px;
                  padding-top: 10px;
                "
                align="left"
              >
                <p style="color: #828282;  font-weight: 600;">Patient Details</p>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-right: 5%;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Age:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ age }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Gender:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_gender " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_gender }}</span>
                      <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_gender }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/height.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Height:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_height" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_height }}</span>

                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_height }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Weight:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_weight " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_weight }}</span>
                       <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_weight }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/alcoholicIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">
                        Alcoholic:
                      </p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.alcoholic" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.alcoholic
                      }}</span>
                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.dependent_alcoholic
                      }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row;">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/smokeIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Smoker:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.smoker" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.smoker
                      }}</span>
                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.dependent_smoker
                      }}</span>
                    </div>
                  </div>

                </div>
                   <v-divider style="width:95%" />
                <p style="color: #828282; font-weight: 600; padding-top:30px;">Description</p>
                <div style="font-weight: normal" align="left">
                  <p>
                    {{ description }}
                  </p>
                </div>
                <br>
                <div v-if="allAttachments.length > 0">
                  <p style="color: #828282; font-weight: 600;">View Attachment</p>
                  <div style="display: flex; flex-wrap: wrap   ">
                    <div v-for="attachments in allAttachments" :key="attachments" style="padding: 5px 15px; margin-bottom: 15px;">
                      <!-- {{attachments}} -->
                      <v-chip target="_blank" @click="downloadItem(attachments)">
                        {{attachments.split("/")[attachments.split("/").length -1]}}
                        <!-- {{attachments.split('#@#')[1]}} -->
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <v-card class="mobileLayoutPatientInfo">
              <div>
                <p class="bookingIdCalss">BOOKING ID: {{ appointment_reference_number }}</p>
              </div>
              <v-divider  style=" margin-bottom:4px"></v-divider>
              <div>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" style="margin-left:15px">
                    <v-row no-gutters>
                      <v-col cols="2" sm="2" style="text-align: left">
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </v-col>
                      <v-col cols="7" sm="5" style="margin-top:10px">
                        <h4 align="left" style="font-weight: 300;font-size: 0.7em">
                          Patient Name
                        </h4>
                        <div
                          style="font-weight: 600; font-size: 0.7em"
                          align="left"
                        >
                          {{ nameOfPatient }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div class="dateTimeMainClass">
                <div>
                  <div style="display:flex; flex-direction:row;margin-left:8%">
                    <img
                      style="width: 25px; height: 25px"
                      src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                    />
                    <p style="margin:2px !important; color:#828282">Date</p>
                    </div>
                    <div style="font-weight: normal">
                    <DateRendered :dateVal="dateOfAppointment" style="font-size: 14px;color:#333333"> </DateRendered>
                    </div>
                </div>
                <div>
                  <div style="display:flex; flex-direction:row; margin-left:12%">
                    <img
                      style="width: 25px; height: 25px"
                      src="https://s3iconimages.mymedicine.com.mm/clock.svg"
                    />
                    <p style="margin:2px !important; color:#828282">Time Slot</p>
                    </div>
                    <div style="font-weight: normal">
                    <TimeRender :timeSlot="timeSlot" style="font-size: 14px;color:#333333"> </TimeRender>
                    </div>
                </div>
              </div>
              <div style="margin: 8px 0px 0px 15px;">
                <p style="color:#828282; text-align:left">Description</p>
              </div>
              <div style="margin: 0px 0px 0px 15px; padding-bottom: 10px; text-align:justify">
                {{description}}
              </div>
              <!-- <v-row class="MobileLayout">
                <div style="margin-left:15px; margin-top:8px">
                  <p style="color:#828282; text-align:left">Description</p>
                </div>
              </v-row>
              <v-row class="MobileLayout">
                  <div style="font-weight: normal; margin: 0px 15px 5px 15px;" align="left">
                    <p>
                      {{ description }}
                    </p>
                  </div>
                </v-row> -->
              <v-row class="MobileLayout" style="margin-left:15px; margin-top:8px">
                  <v-col cols="6" sm="6" class="DocVideoCallcols6Class">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Age: {{ age }}</span
                        >
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                        />
                      </div>
                      <div>
                        <span
                          v-if="patientDetails.customer_gender "
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Gender: {{ patientDetails.customer_gender }}
                        </span>
                        <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Gender: {{ patientDetails.dependent_gender }}
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          v-if="patientDetails.customer_weight "
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Weight: {{ patientDetails.customer_weight }} Lbs</span>
                          <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Weight:{{ patientDetails.dependent_weight }} Lbs</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/height.svg"
                        />
                      </div>
                      <div>
                        <span
                          v-if="patientDetails.customer_height"
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Height: {{ patientDetails.customer_height }} </span>
                          <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Height: {{ patientDetails.dependent_height }} </span>
                      </div>
                    </div>
                  </v-col>
                                    <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/alcoholicIcon.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          v-if="patientDetails.alcoholic "
                          style="
                            font-size: 10.4px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Alcoholic: {{ patientDetails.alcoholic }}</span>
                          <span
                          v-else
                          style="
                            font-size: 10.4px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Alcoholic: {{ patientDetails.dependent_alcoholic }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/smokeIcon.svg"
                        />
                      </div>
                      <div>
                        <span
                          v-if="patientDetails.smoker"
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Smoker: {{ patientDetails.smoker }} </span>
                          <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Smoker: {{ patientDetails.dependent_smoker }} </span>
                      </div>
                    </div>
                  </v-col>
                  <div v-if="allAttachments.length > 0">
                  <p style="color: #828282; font-weight: 600; display:flex; justify-content:flex-start;" class="attachmentsTitleClass">View Attachment</p>
                  <div style="display: flex; flex-direction: column; flex-wrap: wrap;" class="attachmentDataClass">
                    <div v-for="attachments in allAttachments" :key="attachments" style="padding: 5px 15px; margin-bottom: 15px;">
                      <v-chip @click="ViewFile(attachments)" target="_blank" style="width: 200px;">
                        <p class="mb-0" style="max-width: 100%;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; display: inline-block;">
                          {{attachments.split("/")[attachments.split("/").length-1]}}
                        </p>
                      </v-chip>
                    </div>
                  </div>
                </div>
                </v-row>
            </v-card>
            <h3
              style="
                text-align: left;
                margin-top: 30px;
                font-weight: 800;
                margin-bottom: 10px;
                margin-left: 10px;
              "
            >
              Prescription Details
            </h3>
            <v-tabs
              v-model="tab"
              background-color="primary"
              >
              <v-tab v-for="item in items" :key="item.tab">
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" style="width: 70vw" class="viewAppointmentTabItems">
              <v-tab-item >
                <v-card style="padding: 32px 24px;">
                  <!-- <v-row>
                    <v-col cols="12">
                      <div id="form" style="width: 95%;">
                        <fieldset class="fieldset_class" style="padding: 15px;">
                          <legend align="left" style="font-weight: bold;">
                            Covid Related Symptoms
                          </legend>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                              v-model="selected"
                              label="Fever"
                              value="Fever"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Shortness of breath"
                              value="Shortness of breath"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Flu like illness(Sneezing, cough)"
                              value="Flu like illness(Sneezing, cough)"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                              v-model="selected"
                              label="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                              value="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Contact with a person who had travelled to COVID-19 suspected areas"
                              value="Contact with a person who had travelled to COVID-19 suspected areas"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="None of the Above"
                              value="None of the Above"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                              v-model="selected"
                              label="Loss of smell"
                              value="No Smell"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Travelling history within 2 weeks"
                              value="Travelling history within 2 weeks"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </fieldset>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px; margin-left: 10px; padding:15px;">
                    <v-col cols="10" sm="5" md="4" align="left">
                      Allergies
                      <v-textarea class="textarea_class" v-model="allergies" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="left">
                      Diagnosed With
                      <v-textarea class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="left">
                      Current Taking Medicines
                      <v-textarea class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row  style="width: 95%; margin-top: 10px;margin-left: 10px;">
                    <v-col cols="6" sm="6" md="6" align="left">
                      Prescription
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="left">
                      <table style=" width: 100%; background-color: #F1F6FF;">
                        <tr align="left" style="padding: 15px;">
                          <td style="font-size:16px; line-height: 20px;">Medicine</td>
                          <td style="font-size:16px; line-height: 20px;">Unit(Tablet/Syrup)</td>
                          <td style="font-size:16px; line-height: 20px;">Dosage</td>
                        </tr>
                        <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                          <td><v-text-field class="textfield_class" solo outlined
                           v-model="item.medicine"/></td>
                          <td><v-text-field class="textfield_class" solo outlined
                           v-model="item.unit"/></td>
                          <td><v-text-field class="textfield_class" solo outlined
                           v-model="item.dosage"/></td>
                          <td><img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                            <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.unit,item.dosage)"></td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px;margin-left: 10px">
                    <v-col cols="10" sm="5" md="6" align="left">
                      Suggestions (If Any)
                      <v-textarea class="textarea_class" v-model="suggestions" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="6" align="left">
                      Examinations to be done (If Any)
                      <v-textarea class="textarea_class" v-model="exams" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row  style="width: 95%; margin-top: 10px;">
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="need_follow_up"
                      label="Need Follow up appointment"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="referred_to_specialist"
                      label="Referred to a specialist"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="patient_no_show"
                      label="Patient No Show"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="unstable_connectivity"
                      label="Unstable Connectivity"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn color = primary @click="save_prescription"> Update Prescription </v-btn>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left" class="persistingSymptomsClass presentingSysmptoms">
                      Presenting Symptoms
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10" class="persistingSymptomsClass presentingSysmptoms">
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="presenting_symptoms" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                      Past medical history
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="medical_history" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                      Past surgical history
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="surgical_history" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                      Family history
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="family_history" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                      Regular taking/ Current medicine
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="current_taking_medicines" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                      Known Drug Allergies
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="allergies" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                      Vaccination History
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="vaccination_history" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                      Diagnosis<span style="color:red">*</span>
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="diagnosed_with" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" sm="3" md="3" align="left" class="docViewAppointmentMedicalDetails">
                      Treatment
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="left">
                      <table style=" width: 100%; background-color: #F1F6FF;" class="prescriptionTableClass">
                        <tr align="left" style="padding: 15px;">
                          <td style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</td>
                          <td style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</td>
                          <td style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</td>
                          <td style="font-size:16px; line-height: 20px; padding: 5px;">Duration</td>
                        </tr>
                        <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                          <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                           v-model="item.medicine"/></td>
                          <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                           v-model="item.dosage"/></td>
                          <td style="padding:5px;"><v-select :disabled="!prescription_fill_flag" outlined background-color="white" solo :items="frequency_list" v-model="item.frequency"></v-select></td>
                           <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                           v-model="item.duration"/></td>
                          <td><img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                            <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"></td>
                        </tr>
                      </table>
                      <table style=" width: 100%; background-color: #F1F6FF; margin-top:18px" class="PrescriptionTableMobileLayout" v-for="(item,index) in prescription_object" :key=index>
                      <tr align="left">
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Medicine</td>
                      </tr>
                      <tr >
                        <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined :disabled="!prescription_fill_flag"
                         v-model="item.medicine"/></td>
                      <tr>
                      <table >
                      <tr>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Dosage</td>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Frequency</td>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Duration</td>
                      </tr>
                      <tr >
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.dosage"/></td>
                        <td style="padding:5px;"><v-select :disabled="!prescription_fill_flag" outlined background-color="white" solo :items="frequency_list" v-model="item.frequency"></v-select></td>
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.duration"/></td>
                      </tr>
                         </table>
                      </tr>
                       </tr>
                      <tr>
                        <td v-if="prescription_fill_flag">
                          <!-- <img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                          <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"> -->
                          <v-btn width="100%" color="primary" v-if="index == prescription_object.length-1" @click="add_rec" style="background-color: #1467BF;" >
                            + Add More
                          </v-btn>
                          <v-btn width="100%" color="warning" v-else-if="index < prescription_object.length" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)" style="background-color: #EB5757;">
                            Delete Row
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                    </v-col>
                  </v-row>
                   <v-row>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left" class="docViewAppointmentMedicalDetails">
                      Suggestions
                    </v-col>
                    <v-col cols="12" lg="12" sm="12" md="12" xl="12">
                      <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="suggestions" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left" class="docViewAppointmentMedicalDetails">
                      Investigations needed
                    </v-col>
                    <v-col cols="12" lg="12" sm="12" md="12" xl="12">
                      <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                      To Come after
                    </v-col>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docViewAppointmentMedicalDetails">
                      <!-- <v-select background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
                      <!-- <input type="date" v-model="come_after" background-color="#F1F6FF"/> -->
                                  <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :disabled="!prescription_fill_flag"
                                        v-model="followUpDate"
                                        label="Follow Up Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="followUpDate"
                                      @input="menu = false"
                                    ></v-date-picker>
                                  </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                      Refer To
                    </v-col>
                    <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docViewAppointmentMedicalDetails">
                      <v-select background-color="#F1F6FF" :disabled="!prescription_fill_flag" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/>
                    </v-col>
                    <v-col lg="7" sm="7" md="7" xl="7" v-if="selected_specialization.specialization_name == 'Others'">
                      <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="others" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="padding-bottom: 100px;">
                      <v-btn color = primary @click="submit_prescription" v-show="prescription_fill_flag" style="background-color: #1467BF;"> Update Prescription </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <div
                    width="99%"
                    height="60vh"
                    style="
                      margin-right: 2%;
                      border-radius: 12px;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 50px;"
                        class="prescriptionHistoryWrap"
                    >
                      <v-card
                        width="20vw"
                        class="prescriptionHistoryCard"
                        style="border-radius: 12px; margin: 2%"
                        v-for="prescription in prescriptionDetails"
                        :key="prescription.appointment_reference_number"
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: left;
                            margin-top: 15px !important;
                          "
                        >
                          <div>

                            <h4
                              align="left"
                              style="padding-top: 10px; margin-left: 20px"
                              v-if="prescription.diagnosed_with != ''"
                            >
                             Diagnosed With: {{ prescription.diagnosed_with }}
                            </h4>

                            <h4
                              align="left"
                              style="padding-top: 10px; margin-left: 20px"
                              v-else
                            >
                              Diagnosed With: NA
                            </h4>
                          </div>

                          <div
                            style="
                              display: flex;
                              flex-direction: row;
                              justify-content: space-between;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-direction: column;
                                padding: 2px;
                                margin-top: 5%;
                                margin-left: 5%;
                              "
                            >
                              <div>
                                <h6
                                  style="
                                    margin-top: 20%;
                                    color: #828282 !important;
                                  "
                                  align="left"
                                >
                                  Doctor Name
                                </h6>
                              </div>
                              <div>
                                <p
                                  style="margin-top: 2px; font-weight: 700; font-size: 0.8em"
                                  align="left"
                                >
                                  {{ prescription.doctor_name }}
                                </p>
                              </div>
                            </div>

                            <div>
                              <div
                                style="
                                  display: flex;
                                  flex-direction: row;
                                  margin-top: 35%;
                                  margin-right: 10px;
                                "
                              >
                                <div>
                                  <img
                                    style="width: 25px; height: 25px"
                                    src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                                  />
                                </div>
                                <div style="margin-top: 3px; margin-left: 5px; font-size: 0.8em">
                                  <strong>
                                     <DateRendered :dateVal=" prescription.date_of_appointment"> </DateRendered>
                                    </strong>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style="margin-top: 10px; margin-bottom: 10px">
                            <v-btn
                              color="primary"
                              width="85%"
                              @click="viewPrescription(prescription._id)"
                              style="border-radius: 8px; bottom: 10px; background-color: #1467BF;"
                            >
                              View Prescription
                            </v-btn>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>

        </div>
        <div v-else style="margin-top: 10%;">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import DateRendered from "../Customer/dateRender.vue";
import TimeRender from "../Customer/TimeRender.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';


export default {
  name: "CustomerHome",
  data() {
    return {
      //viewAppointments
      book_id: "",
      patientDetails:"",
      appointment_reference_number: "",
      pageName: "viewParticularAppointment",
      currentUser: "",
      currentUserName: "",
      nameOfPatient: "",
      timeSlot: "",
      dateOfAppointment: "",
      description: "",
      reason: "",
      patient_id: "",
      age: "",
      allAttachments: "",
      tab: null,
      items: [
        { tab: "Prescription", content: "Tab 1 Content" },
        { tab: "Prescription History", content: "Tab 2 Content" },
      ],
      prescriptionId: "",
      customer_gender:"",
      particularPrescriptionDetails: "",
      unstable_connectivity: false,
      patient_no_show: false,
      referred_to_specialist: false,
      prescription_fill_flag: true,
      need_follow_up: false,
      exams: "",
      suggestions: "",
      current_taking_medicines: "",
      disable_field: false,
      current_epoch_time: "",
      diagnosed_with: "",
      presc_dialog: false,
      allergies: "",
      selected: [],
      date_of_appointment: "",
      prescriptionDetails: "",
      isLoading : true,
      break_here_for_newtemplate_variable: "",
      presenting_symptoms: "",
      medical_history: "",
      surgical_history: "",
      family_history: "",
      save_dialog: false,
      current_taking_medicines: "",
      allergies: "",
      vaccination_history: "",
      diagnosed_with: "",
      investigations_needed: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      frequency_list: ["OD","CM","HS","BD","TDS","QID","PRN"],
      follow_up_list:["One Week", "Two week", "One Month", "Two Month","Three Month","Not Required"],
      come_after: "",
      others: "",
      specialization_list: [{'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"}],
      selected_specialization: {'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"},
      followUpDate:"",
      menu:"",
    };
  },
  mounted() {
    this.pageName = "AdminHome";
    document.title = "View Appointment"
    this.currentUser = this.$cookies.get("doctorToken");
    var presc_dets = "";
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var params = this.$route.params;
      this.book_id = params["booking_id"];
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        bookingId: this.book_id,
        page: 1,
        limit: 8
      };
      axios_auth_instance_doctor.post("/doctorViewAppointmentPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.doctor_data.doctor_name;
        this.doctor_id = successResponse.data.doctor_data._id;
        var appointment_object = successResponse.data.appointment_data;
        this.current_epoch_time = successResponse.data.current_epoch_time;
        if (Object.keys(appointment_object).length > 0) {
          this.appointment_reference_number = appointment_object.appointment_reference_number;
          if(appointment_object.booking_epoch_time - this.current_epoch_time > 0){
            this.prescription_fill_flag = false;
         }
          this.nameOfPatient = appointment_object.booked_for_name;
          this.reason = appointment_object.reason;
          this.description = appointment_object.description;
          this.timeSlot = appointment_object.time_slot;
          this.dateOfAppointment = appointment_object.date_of_appointment;
          this.patient_id = appointment_object.booked_for_id;
          this.specialization = appointment_object.specialization_name;
          this.allAttachments = appointment_object.file_attachment;

        }
        this.patientDetails = successResponse.data.patient_data;
        if(this.patientDetails.customer_dob){
          this.age = this.getAge(this.patientDetails.customer_dob);
        }
        else{
            this.age = this.getAge(this.patientDetails.dependent_dob);
        }

        this.prescriptionDetails = successResponse.data.prescription_data.docs;

        if(successResponse.data.appointment_prescription_data.length > 0)
        {
          presc_dets = successResponse.data.appointment_prescription_data[0];
          this.selected = presc_dets['covid_related_symptoms'];
          this.allergies = presc_dets['allergies'];
          this.prescription_fill_flag = false;
          this.diagnosed_with = presc_dets['diagnosed_with'];
          this.current_taking_medicines = presc_dets['current_taking_medicines'];
          this.suggestions = presc_dets['suggestion'];
          if(presc_dets['need_follow_up'] == true)
            this.need_follow_up = "true"
          this.exams = presc_dets['examination_to_be_done'];
          if(presc_dets['patient_no_show'] == true)
            this.patient_no_show = presc_dets['patient_no_show'];
          if(presc_dets['patient_no_show'] == true)
            this.referred_to_specialist = "true";
          if(presc_dets['unstable_connectivity'] == true)
          {
            this.unstable_connectivity = "true";
          }
          if(presc_dets['prescription'].length > 0)
          {
            this.prescription_object = presc_dets['prescription'];
          }
          this.presenting_symptoms = presc_dets.presentSymptoms;
          this.medical_history = presc_dets.medicalHistory;
          this.surgical_history = presc_dets.surgicalHistory;
          this.family_history = presc_dets.familyHistory;
          this.vaccination_history = presc_dets.vaccinationHistory;
          this.investigations_needed = presc_dets.investigationNeeded;
          this.followUpDate = presc_dets.next_followup_date;
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", data)
        .then((readAllSpecializationsResponse) => {
          var spec_list = readAllSpecializationsResponse.data.data;
          this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
          spec_list.forEach((x) =>{
            this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
          });
          let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
          if(obj)
          {
            this.selected_specialization = obj;
          }
          else if(successResponse.data.appointment_prescription_data.length > 0)
          {
            this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
            this.others = presc_dets.referredSpecialistName;
          }
          else if(successResponse.data.appointment_prescription_data.length == 0)
          {
            this.prescription_fill_flag = true;
          }
        })
        .catch((readAllSpecializationsError) =>{
        });
        this.isLoading = false;
      })
      .catch((errorResponse) => {
        this.isLoading = false;
        handleError(errorResponse, this.$router, 'doctorViewAppointmentPage', 'DoctorLogin', 'doctorToken');
      });
    }
  },
  components: {
    NavBar,
    DateRendered,
    TimeRender,
    SideBar
  },
  methods: {
    downloadItem(file)
      {
        var name_arr = file.split("/");
        var name = name_arr[name_arr.length -1];
        var read_body = {
          file_name: name,
          token : this.currentUser,
          typeOfUser : "DOCTOR",
        };

        axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
        .then((readSuccess) =>{
          axios.get(readSuccess.data.data, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], { type: response.data.type });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = file;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            console.log("Error Downloading file: " + error);
          });
        })
        .catch((readError) =>{
          console.log(readError);
        });
      },
    submit_prescription(){
      this.presc_dialog = true;
    },
    saved_prescription(){
      this.save_dialog = false;
      this.$router.go();
    },
    ViewFile(filename)
    {
      var name_arr = filename.split("/");
      var name = name_arr[name_arr.length -1];
      var read_body = {
        file_name: name,
        token : this.currentUser,
        typeOfUser : "DOCTOR",
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
      .then((readSuccess) =>{
        //window.open(readSuccess.data.data,'_blank');
        var url = readSuccess.data.data;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
          if (this.status == 200) {
            var myBlob = this.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(myBlob);
            link.download = name;
            link.click();
          }
        };
        xhr.send();
      })
      .catch((readError) =>{
        console.log(readError);
        window.alert("Error while reading the file");
      });
    },
    add_rec(){
        this.prescription_object.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
    },
    remove_rec(medicine,dosage,frequency,duration)
      {
        this.prescription_object.forEach(function(item, index, object) {
          if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration) {
            object.splice(index, 1);
          }
        });
    },
    viewPrescription(prescriptionID) {
      this.prescriptionId = prescriptionID;
      let routeData = this.$router.push({
        path: "/doctor/viewPrescription/" + prescriptionID,
      });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    save_prescription() {
      var DateInDateformat = new Date(this.dateOfAppointment)
      if(this.come_after == "One Week"){
        DateInDateformat.setDate(DateInDateformat.getDate()+7)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
      else if(this.come_after == "Two Week"){
         DateInDateformat.setDate(DateInDateformat.getDate()+14)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
       else if(this.come_after == "One Month"){
          DateInDateformat.setMonth(DateInDateformat.getMonth()+1)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);

      }
       else if(this.come_after == "Two Month"){
          DateInDateformat.setMonth(DateInDateformat.getMonth()+2)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
       else if(this.come_after == "Three Month"){
          DateInDateformat.setMonth(DateInDateformat.getMonth()+3)
        this.followUpDate = DateInDateformat.toISOString().substr(0,10);
      }
      var presc_body = {
        covidRelatedSymptoms: this.selected,
        allergies: this.allergies,
        diagnosedWith: this.diagnosed_with,
        currentMedication: this.current_taking_medicines,
        suggestion: this.suggestions,
        examinationTBD: this.exams,
        followUp: this.need_follow_up,
        patientNoShow: this.patient_no_show,
        referredSpecialist: this.referred_to_specialist,
        unstableConnection: this.unstable_connectivity,
        appointmentReferenceNo: this.appointment_reference_number,
        doctorName: this.currentUserName,
        doctorId: this.doctor_id,
        customerName: this.nameOfPatient,
        customerId: this.patient_id,
        appointmentDate: this.dateOfAppointment,
        specialization: this.specialization,
        break_here_for_newtemplate_variable: "",
        presentSymptoms: this.presenting_symptoms,
        medicalHistory: this.medical_history,
        surgicalHistory: this.surgical_history,
        familyHistory: this.family_history,
        currentMedication: this.current_taking_medicines,
        allergies: this.allergies,
        vaccinationHistory: this.vaccination_history,
        diagnosedWith: this.diagnosed_with,
        investigationNeeded: this.investigations_needed,
        comeAfter: this.come_after ? this.come_after : "Not Required",
        followUpDate: this.followUpDate ? this.followUpDate : "",
        referredSpecialistName: this.selected_specialization.specialization_name != 'Others' ? this.selected_specialization.specialization_name : this.others,
        token : this.currentUser,
        typeOfUser : "DOCTOR"
      }
      var prescriptions = [];
      this.prescription_object.forEach(function(item, index, object) {
        if (item.medicine !== '') {
          prescriptions.push(item);
        }
      });
      if(prescriptions.length == 0)
        presc_body.prescription = [];
      else if(prescriptions.length > 0)
        presc_body.prescription = prescriptions;
      axios.post(process.env.VUE_APP_BACKEND_URL+"/createPrescription",presc_body)
      .then((createPrescriptionResponse) =>{
        this.presc_dialog = false;
        this.save_dialog = true;
      })
      .catch((createPrescriptionError) =>{
        if(createPrescriptionError.response.status == 403)
          window.alert("Cannot update a blank prescription");
      });
    },
    redirectToHomePage(){
      // this.$router.push({
      //   name: 'DoctorHome'
      // })
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.PrescriptionTableMobileLayout{
  display: none;
}
.theme--light.v-divider
  {
    border: 1px solid #C4C4C4 !important;
    width: 95%;
    margin: auto;
  }
.fieldset_class
  {
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: none;
  }
  .textfield_class {
    box-sizing: border-box;
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 10px !important;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

    /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey !important;
    width: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey !important;
  }
  .mobileLayoutPatientInfo{
    display: none;
  }
  .titleClass{
    display: block !important;
  }
  .header{
    display: none !important;
  }
  @media (min-width: 320px) and (max-width: 1024px){
    .PrescriptionTableMobileLayout{
      display: block;
    }
    .header {
      display: block !important;
      box-shadow: 0px 2px 15px 0px #00000026;
      padding: 16px 20px !important;
    }
    .prescriptionTableClass{
      display: none;
    }
    .docViewAppointmentNavBar{
      display: none;
    }
    .mobileLayoutPatientInfo{
      display: block;
    }
    .docViewAppointmentMainContainer{
      left: 0px !important;
      top:0px !important;
      height: 100vh !important;
      width: 100% !important;
    }
    .docViewAppointmentPatientInfo{
      display: none;
    }
    .bookingIdCalss{
      padding: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      text-align: left;
      margin: 0px !important;
    }
    .dateTimeMainClass{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px 15px;
    }
    .viewAppointmentTabItems{
      width: unset !important;
    }
    .persistingSymptomsClass{
      max-width: unset;
      flex: unset;
      padding:unset;
    }
    .presentingSysmptoms{
      padding-left:12px ;
    }
    .docViewAppointmentMedicalDetails{
        max-width: unset;
        flex: unset;
        text-align: left;
    }
    .titleClass{
    display: none !important;
    }
    .prescriptionHistoryCard{
      width: 95% !important;
      margin-left:3% !important ;
    }
    .prescriptionHistoryWrap{
      flex-direction: column;

    }
  }
</style>